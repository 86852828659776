<template>
  <LayoutMainPages>
    <div class="collection-info-container" :class="{ 'flex-col': isMobileDevice }">
      <CollectionInfo
        @onEmitShareEvent="onEmitShareEvent"
        :player="player"
      />
    </div>
    <CollectionAssetsList class="mt-s48" :player="player"/>
  </LayoutMainPages>
</template>

<script>
import { mapActions } from 'vuex';
import { CollectionAssetsList, CollectionInfo } from '@/modules/collections';
import { LayoutMainPages } from '@/components/misc';

export default {
  name: 'Player',
  components: {
    CollectionAssetsList,
    CollectionInfo,
    LayoutMainPages,
  },

  computed: {
    player() {
      return this.lodashGet(this.$store.state.api, 'player', {});
    },
  },

  methods: {
    ...mapActions('user', ['requiresKycComplete']),

    async loaded() {
      await this.$store.dispatch('events/track', {
        event: 'PLAYER_VIEWED',
        variables: {
          id: this.player.id,
          id_hr: this.player.id_hr,
          sport: this.lodashGet(this.player, 'sport.id_hr', ''),
          team: this.lodashGet(this.player, 'team.name', ''),
          total_value: this.player.total_value,
          asset_count: this.player.asset_count,
          change_24h: this.player.change_24h,
          change_24h_pct: this.player.change_24h_pct,
        },
      });
    },

    async onEmitShareEvent() {
      await this.$store.dispatch('events/track', {
        event: 'PLAYER_SHARED',
        variables: {
          id: this.player.id,
          id_hr: this.player.id_hr,
          sport: this.player.sport ? this.player.sport.id_hr : '',
          team: this.player.team ? this.player.team.name : '',
          total_value: this.player.total_value,
          asset_count: this.player.asset_count,
          change_24h: this.player.change_24h,
          change_24h_pct: this.player.change_24h_pct,
        },
      });
    },
  },

  async mounted() {
    if (!this.globalIsLoading) {
      // if page is already loaded when mounted, it means this is the initial loading (i.e. page refresh)
      await this.loaded();
    }
  },

  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
};
</script>

<style scoped>
  .collection-info-container {
    @apply flex justify-between items-end border-b border-border;
  }
</style>
